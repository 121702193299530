.dot {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #000000;
  border-radius: 50%;
  align-self: center;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}
