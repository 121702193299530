/* container */
.container {
  padding: 0px 20vw;
}

/* top header */
.header {
  padding-top: 20vh !important;
}

section {
  display: block;
  padding-bottom: 50px;
}

/* filters */

.filterBtnsContainer {
  display: flex;
}

.filterBtns {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
  border-radius: 30px;
  padding: 6px 15px;
  font-size: 15px;
  position: relative;
}

.filterBtnsActive {
  color: white;
  background-color: black;
  opacity: 1 !important;
}

.filterBtns:hover {
  opacity: 0.6;
}

/* pagination/ */

.numbering {
  background-color: white;
  border: none;
  margin-right: 10px;
}

.currPage {
  border-radius: 15px;
  background-color: black;
  color: white !important;
}

/* blog list */

.blogList {
  display: grid;
}

.noContent {
  margin-top: 0px;
  padding-top: 30px;
}

/* post preview */
.article {
  margin-top: 75px;
  display: grid;
  grid-template-areas:
    "type type type date"
    "title title title author"
    "desc desc pic pic"
    "readmore readmore pic pic"
    ". . pic pic";
  grid-template-columns: repeat(2, 1fr);
  justify-items: flex-start;
  align-items: flex-start;
  gap: 16px 0;
  width: 100%;
}

.article-responsive {
  grid-template-areas:
    "type"
    "title"
    "author"
    "date"
    "pic"
    "desc"
    "readmore" !important;
  grid-template-columns: repeat(1, 1fr) !important;
}

.postType {
  grid-area: type;
  margin: 0px;
}
.postTitle {
  grid-area: title;
  margin: 0px;
}
.postAuthor {
  grid-area: author;
  margin: 0px;
}
.postDate {
  grid-area: date;
  margin: 0px;
}
.postDesc {
  grid-area: desc;
  margin: 0px;
  padding: 0px 30px 0px 0px;
}
.postPic {
  grid-area: pic;
}
.postReadMore {
  grid-area: readmore;
}

.postImg {
  width: 30vw;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  cursor: pointer;
}

.postImg-responsive {
  width: 100%;
  max-height: 30vh;
  margin: 10px 0px 20px 0px;
}
