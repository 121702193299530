:root {
  --duration: 0.3s;
  --hoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  --arrowSpacing: 5px;
  --arrowHoverTransition: var(--duration) cubic-bezier(0.215, 0.61, 0.355, 1);
  --arrowHoverOffset: translateX(3px);
}

.navbar {
  position: fixed;
  width: 100%;
  backdrop-filter: blur(15px);
  background-color: hsla(0, 0%, 100%, 0.8);
  display: grid;
  grid-template-columns: 10vw 30vw 60vw;
  z-index: 10;
}

/* logo side */

.logo-container {
  width: 10vw;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.logo-container > img {
  height: 4vh;
  min-height: 40px;
  margin: 15px 0px 15px 20px;
}

/* navitems stuff */

.nav-items-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-items > li {
  margin: 0px 3vw;
}

.nav-items-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-items-menu > li {
  margin: 10px 3vw;
}

.navItem {
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
}

.navItem:hover {
  opacity: 0.6;
}

/* solid navitem */

.navItemContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navItemContentMenu {
  justify-content: unset !important;
  align-items: unset !important;
}

.navItemSolid {
  color: white;
  position: relative;
  display: inline-block;
  background-color: rgb(46, 46, 46);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 16.5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all var(--duration) cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 5px 16px 5px 16px;
  cursor: pointer;
  border-width: 0px;
  font-size: 15px;
}

.navItemSolidLight {
  color: black !important;
  background-color: white !important;
}

.navItemSolid::after {
  content: "";
  border-radius: 30px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all var(--duration) cubic-bezier(0.165, 0.84, 0.44, 1);
}

.navItemSolid:hover::after {
  opacity: 1;
}

.hoverArrow {
  position: relative;
  top: 1px;
  margin-left: var(--arrowSpacing);
  stroke-width: 2px;
  fill: none;
  stroke: currentColor;
}

.hoverArrow .hoverArrowLinePath {
  opacity: 0;
  transition: opacity var(--hoverTransition, var(--arrowHoverTransition));
}

.navItemSolid:hover .hoverArrowLinePath {
  opacity: 1;
}

.hoverArrow .hoverArrowTipPath {
  transition: transform var(--hoverTransition, var(--arrowHoverTransition));
}

.navItemSolid:hover .hoverArrowTipPath {
  transform: var(--arrowHoverOffset);
}

/* hamburger */

.hamburger {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  z-index: 20;
}

.hamburger-container {
  border: 0px;
  background: none;
  outline: none;
}

.hamburger-line {
  width: 25px;
  height: 3px;
  background-color: #191414;
  margin: 4px 0;
  transition: 0.4s;
  display: block;
}

.hamburger-separate {
  display: none;
}

/* menu */

.menu {
  background: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: auto;
  color: white;
  display: grid;
  grid-template-columns: auto;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
