.a {
  font-weight: bold;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
}

.a:hover {
  text-decoration: underline;
  opacity: 0.6;
}
